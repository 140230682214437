// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCltuo0zgqq2tcG7FlqaJPLd5zMb1E7WaY",
  authDomain: "fnarc-84be5.firebaseapp.com",
  projectId: "fnarc-84be5",
  storageBucket: "fnarc-84be5.appspot.com",
  messagingSenderId: "449842492433",
  appId: "1:449842492433:web:c22116bf0649932caa6db0",
  measurementId: "G-PC7FTLDLVV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export default app;
